import { useEffect, useState } from 'react'

export const useScrollEnd = (): boolean => {
  const [isScrollEnd, setIsScrollEnd] = useState(false)

  const handleScroll = (): void => {
    const windowDocument = window.document.querySelector('.ant-layout-content')
    const { scrollTop, clientHeight, scrollHeight } = windowDocument as Element

    if (scrollTop + clientHeight >= scrollHeight - 150) {
      setIsScrollEnd(true)
    } else {
      setIsScrollEnd(false)
    }
  }

  const setScrollEvent = (): () => void => {
    const windowDocument = window.document.querySelector('.ant-layout-content')
    windowDocument?.addEventListener('scroll', handleScroll)
    return () => {
      windowDocument?.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    setScrollEvent()
  }, [])

  return isScrollEnd
}
