import { getToken } from "@prev-front/api-adapter"

export const estadosOab = [
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' }
]

export const getProfile = async (): Promise<any> => {
  try {
    const localToken = getToken()
    if (localToken) {
      const base64Url = localToken.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))

      const data = JSON.parse(jsonPayload)
      return data
    }
  } catch (error) {
    localStorage.removeItem('access_token_prev')
    location.href = '/login'
  }
}
export const getInitials = (name: string | undefined): string => {
  if (!name) return ''
  const split = name.split('')
  return split?.length > 0 ? split[0] : ""
  // if (split.length > 1) {
  //   return `${split[0][0]}${split[1][0]}`
  // } else if (split.length > 0) {
  //   return `${split[0][0]}`
  // }
  // return ''
}

export const getSlugFromUrl = (): string | null => {
  const currentUrl = window.location.href
  const urlParts = currentUrl.split('//')
  const isLocal = checkLocalhost()

  if (urlParts.length > 1) {
    const domainAndPath = urlParts[1].split('/')
    const domainParts = domainAndPath[0].split('.')
    console.log(domainParts)

    // Verifica se há um subdomínio e retorna o slug
    if (!isLocal && domainParts.length > 2) {
      console.log("entrei primeiro")
      return domainParts[0]
    }
    if(isLocal && domainParts.length > 1) {
      console.log("entrei sec")
      return domainParts[0]
    }
  }

  return null
}

export const checkLocalhost = (): boolean => {
  const currentUrl = window.location.href
  const isLocal = currentUrl.includes('localhost')
  console.log('islocal', isLocal)
  return isLocal
}