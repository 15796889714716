import { useEffect, useState } from "react"
import { navigate } from "@reach/router"

export interface UseSearchInterface {
    query: string
    setQuery: (query: string) => void
    actualPath: string
    setActualPath: (path: string) => void
    handleFindBy: (path: string) => void
    afterSearch: () => void
    shouldSearch: boolean
    setShouldSearch: (shouldSearch: boolean) => void
}

export const useSearch = (): UseSearchInterface => {
    const [query, setQuery] = useState(localStorage.getItem("searchQuery") ?? "")
    const [actualPath, setActualPath] = useState('/')
    const [shouldSearch, setShouldSearch] = useState(false)

    const handleFindBy = (path: string): void => {
        saveSearch(query, path)
        if (path === 'peticoes') {
          setActualPath('/peticoes')
        } else if (path === 'clientes') {
          setActualPath('/clientes')
        } else if (path === 'jurisprudencia') {
          setActualPath('/jurisprudencia')
        }
        if (navigate) {
           navigate(`/${path}`)
           setShouldSearch(true)
        }
    }

    const afterSearch = () => {
        localStorage.removeItem('searchQuery')
    }

    useEffect(() => {
        if(actualPath === "/" && !query && !shouldSearch) setShouldSearch(true)
    }, [actualPath])

    const saveSearch = (search: string, where: string): void => {
        localStorage.setItem('searchQuery', search)
        const searchHistory =
            JSON.parse(localStorage.getItem('searchHistory') || '[]') || Array(5)

        const uniqueHistory = searchHistory?.filter(
            (item: { search: string, where: string }) => {
            return !(
                item.search.toLowerCase() === search.toLowerCase() &&
                item.where === where
            )
            }
        )

        uniqueHistory?.unshift({ search, where })
        if (uniqueHistory?.length > 5) {
            uniqueHistory?.pop()
        }
        localStorage.setItem('searchHistory', JSON.stringify(uniqueHistory))
    }

    return {
        query,
        setQuery,
        actualPath,
        setActualPath,
        handleFindBy,
        afterSearch,
        shouldSearch,
        setShouldSearch
    }
}