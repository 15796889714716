// Anything exported from this file is importable by other in-browser modules.
import { useScrollEnd } from './hooks/useScrollEnd'
import { useSearch, type UseSearchInterface } from "./hooks/useSearch"
import { SearchContext } from "./contexts/SearchContext"
import useWidth from './hooks/useWidth'
import { getProfile } from './utils'
import styled from 'styled-components'
import React from 'react'

// Lazy-loaded components
export const CasosPreview = React.lazy(() => import('./casos/CasosPreview').then(module => ({ default: module.CasosPreview })));
export const LocalLoading = React.lazy(() => import('./general/LocalLoading').then(module => ({ default: module.LocalLoading })));
export const JobsSelect = React.lazy(() => import('./general/JobSelect').then(module => ({ default: module.JobsSelect })));
export const DecisionPreview = React.lazy(() => import('./jurisprudencia/DecisionPreview').then(module => ({ default: module.DecisionPreview })));
export const TemplateSmallHeader = React.lazy(() => import('./prev-template/TemplateSmallHeader').then(module => ({ default: module.TemplateSmallHeader })));
export const ButtonUpload = React.lazy(() => import('./components/ButtonUpload').then(module => ({ default: module.ButtonUpload })));
export const PetitionPreview = React.lazy(() => import('./peticoes/Preview').then(module => ({ default: module.PetitionPreview })));
export const LaudoPreview = React.lazy(() => import('./laudos/LaudoPreview').then(module => ({ default: module.LaudoPreview })));

export {
  useScrollEnd,
  getProfile,
  useWidth,
  styled,
  useSearch, 
  UseSearchInterface,
  SearchContext
}

export * from '@prev/ui-kit'
